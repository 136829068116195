import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

class ArlingtonTX extends React.Component {
	render() {
		const siteTitle = 'J&J Gates Service and Design in Arlington, TX'
		const siteDescription =
			'We Offer Professional Gate Design and Installation in Arlington, TX. Call  to Find Out More About Our Gate Services and Free Estimates'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">
							Arlington, TX Automatic Gate Installation & Services
						</h1>
						<hr />
					</div>
				</div>
				<div className="container gates-container">
					<div className="row">
						<div className="col-md-12">
							<h4>Our Arlington Location</h4>
							<p className="font-weight-bold">
								J & J Gates Service And Design 2000 Camden Ct Arlington, TX
								76013 <br />
								<a href="tel:8174662794">(817)-466-2794</a>
							</p>
							<hr />
							<p>
								J&J Automatic Gate Service & Design install your gate faster
								than any other similar contractor in Fort Worth. It’s partially
								because we’ve been doing this for more than a decade now. Other
								companies take weeks longer. Feel free to ask them their typical
								project length.
							</p>
							<p>
								And it’s also because automatic driveway gates are all we do.
								When fencing contractors need a gate installed right and fast,
								they call us. When you have a lot of money invested in a
								project, you want it done right and on or ahead of time. And
								that’s always what you get.
							</p>
							<p>
								Need ideas on what you can do? Don’t worry. Our professionals
								can show you dozens of projects done before and help you think
								through all your options.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4>Common Questions</h4>
							<h5>1. What can I do to customize my gate?</h5>
							<p>
								With J&J, just about anything you want. We’ll take time to
								explore your needs and walk you through all your options. You
								can spend just a few thousand, or practically as much as you
								want, on your gate. The average customer spends around $10,000
								or so. Some gates can cost more than $100,000.
							</p>
							<h5>2. Who do you install gates for? </h5>
							<p>
								Our typical customer is a homeowner with some cash saved for a
								project that makes them feel good. However, we serve all
								homeowners with practically any budget, and any kind of business
								in Burleson, TX too.
							</p>
							<h5>3. Do you install anything besides gates?</h5>
							<p>
								Honestly, we do very little outside of gate installation,{' '}
								<Link to="/gates/services/gate-repair/">repair</Link>, and
								maintenance. It’s that specialization that keeps the quality of
								your gate, and the service needed to install it, high.
							</p>
							<h5>4. Why Choose J&J Gate Services to Install Your New Gate?</h5>
							<p>
								We have excellent ratings you’ll find come from more than 10
								years of experience in gate repair and installation. That means
								we know how to install your gate fast. The typical{' '}
								<Link to="/gates/services/gate-installation/">
									installation
								</Link>{' '}
								takes around a week or two, while competitors take 3-4, or even
								more. And, you’ll be involved in the process from day one so you
								get your custom gate exactly the way you want it.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<iframe
								style={{ border: 0 }}
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3357.0416863317696!2d-97.14614768481964!3d32.71151998099195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7cdb82b33241%3A0x51706a1dd12c3ec!2sJ+%26+J+Gates+Service+and+Design!5e0!3m2!1sen!2sus!4v1450710364067"
								allowFullScreen="allowfullscreen"
								width="100%"
								height={450}
								frameBorder={0}
								title="Arlington TX"
							/>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ArlingtonTX

export const ArlingtonTXPageQuery = graphql`
	query ArlingtonTXPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
